<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Use the phase diagram for a pure unknown substance shown here to answer each of the
        questions below:
      </p>

      <img
        class="mb-4"
        style="width: 440px"
        :src="imagePath"
        alt="Phase diagram: unknown substance"
      />

      <p class="mb-2">a) What phase will the unknown substance be in at SATP?</p>

      <v-radio-group v-model="inputs.phaseSATP" class="pl-6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
          row
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-4">
        b) At what pressure and temperature does the triple point occur in this unknown substance?
      </p>

      <p class="mb-2">
        <calculation-input
          v-model="inputs.Tcrit"
          class="mb-3"
          style="width: 65%"
          prepend-text="$\text{T}$"
          append-text="$\text{K}$"
          dense
          :disabled="!allowEditing"
        />
      </p>
      <p class="mb-5">
        <calculation-input
          v-model="inputs.Pcrit"
          class="mb-3"
          style="width: 65%"
          prepend-text="$\text{P}$"
          append-text="$\text{atm}$"
          dense
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-4">
        c) At what pressure and temperature does the unknown substance become a supercritical fluid?
      </p>

      <p class="mb-2">
        <calculation-input
          v-model="inputs.Tsf"
          class="mb-3"
          style="width: 65%"
          prepend-text="$\text{T}$"
          append-text="$\text{K}$"
          dense
          :disabled="!allowEditing"
        />
      </p>
      <p class="mb-10">
        <calculation-input
          v-model="inputs.Psf"
          class="mb-3"
          style="width: 65%"
          prepend-text="$\text{P}$"
          append-text="$\text{atm}$"
          dense
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-5">
        d) If the substance is heated at a constant pressure of
        <stemble-latex :content="pressurePartD + '$\\text{atm}$'" />
        from
        <stemble-latex :content="TiPartD + '$\\text{K}$'" />
        to
        <stemble-latex :content="TfPartD + '$\\text{K,}$'" />
        the substance will change from the
        <v-select
          v-model="inputs.state1"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
          placeholder="choose state"
        />
        state to the
        <v-select
          v-model="inputs.state2"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
          placeholder="choose state"
        />
        state.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question247',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        phaseSATP: null,
        Tcrit: null,
        Pcrit: null,
        Tsf: null,
        Psf: null,
        state1: null,
        state2: null,
      },
      options1: [
        {expression: '$\\text{Solid}$', value: 'solid'},
        {expression: '$\\text{Liquid}$', value: 'liquid'},
        {expression: '$\\text{Gas}$', value: 'gas'},
      ],
      items: ['solid', 'liquid', 'gas'],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          return {
            // gas , ~170 K, ~0.08 atm, 360 K, 35 atm, solid, liquid
            imagePath: '/img/assignments/phaseDiagram1.png',
            pressurePartD: '$\\text{0.50}$',
            TiPartD: '$\\text{100}$',
            TfPartD: '$\\text{200}$',
          };
        case 2:
          return {
            // gas, 160 K, 0.075 atm, 305 K, 20 atm, liquid, gas
            imagePath: '/img/assignments/phaseDiagram2.png',
            pressurePartD: '$\\text{0.50}$',
            TiPartD: '$\\text{200}$',
            TfPartD: '$\\text{300}$',
          };
        case 3:
          return {
            // liquid, 220 K, 0.08 atm, 330 K, 20 atm, solid, gas
            imagePath: '/img/assignments/phaseDiagram3.png',
            pressurePartD: '$\\text{0.50}$',
            TiPartD: '$\\text{70}$',
            TfPartD: '$\\text{350}$',
          };
        case 4:
          return {
            // liquid, 165 K, 0.08 atm, 375 K, 10 atm, solid, gas
            imagePath: '/img/assignments/phaseDiagram4.png',
            pressurePartD: '$\\text{0.02}$',
            TiPartD: '$\\text{100}$',
            TfPartD: '$\\text{250}$',
          };
        default:
          return {
            imagePath: null,
            pressurePartD: null,
            TiPartD: null,
            TfPartD: null,
          };
      }
    },
    imagePath() {
      return this.versionData.imagePath;
    },
    pressurePartD() {
      return this.versionData.pressurePartD;
    },
    TiPartD() {
      return this.versionData.TiPartD;
    },
    TfPartD() {
      return this.versionData.TfPartD;
    },
  },
};
</script>
